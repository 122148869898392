import React, { ElementRef, FC } from 'react';
import {
  makeStyles,
  Dialog,
  DialogActions,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
// import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from 'src/theme';

export interface DefaultDialogProps extends DialogProps {
  title?: string;
  actions?: ElementRef<any>;
}

export const defaultProps = {
  title: '',
  dialogProps: {}
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 16,
    boxShadow: '0px 4px 15px rgba(196, 196, 196, 0.25)',
    border: '1px solid rgba(104, 127, 146, 0.2)',
    minHeight: 288
  },
  paperWidthSm: {
    // maxWidth: 789
  },
  headingWrapper: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    alignItems: 'center',
    // height: 88,
    justifyContent: 'space-between',
    borderBottom: '1px solid #dcdcdc',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3)
    }
  },
  dialogHeading: {
    flexGrow: 1,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    textAlign: 'center',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  closeButton: {
    width: 48,
    height: 48,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#5FA0B0',
    color: theme.palette.blue.main,
    padding: 0
  },
  dialogActions: {
    height: 80,
    boxShadow: '0px -4px 15px rgba(219, 219, 219, 0.25)',
    borderRadius: '0px 0px 4px 4px',
    justifyContent: 'center'
  },
  spacing: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4)
    }
  }
}));

const DefaultDialog: FC<DefaultDialogProps> = ({
  title,
  children,
  actions,
  onClose,
  ...dialogProps
}) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      {...dialogProps}
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm
      }}
    >
      {title && (
        <Box className={classes.headingWrapper}>
          <Typography className={classes.dialogHeading}>{title}</Typography>
          {/* <IconButton
            className={classes.closeButton}
            onClick={e => onClose(e, 'backdropClick')}
          >
            <CloseIcon />
          </IconButton> */}
          <IconButton onClick={e => onClose(e, 'backdropClick')} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {children}
      {actions && (
        <DialogActions
          classes={{ root: classes.dialogActions, spacing: classes.spacing }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DefaultDialog;
