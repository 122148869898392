import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import {
  ConversationMessageService,
  IConversationMessage,
  ConversationTypeEnum,
  IConversation,
  IPaginationRequest,
  ThreadService,
  ParentProviderService,
  // TwilioMessageService,
  ConversationAuthorEnum,
  ConversationStatusEnum,
  ProviderService,
} from 'shared';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';
import uniqueId from 'lodash/uniqueId';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import moment from 'moment';

interface ChatState {
  isLoading?: boolean,
  showComposer?: boolean,
  showConversion?: boolean,
  showNewConversion?: boolean,
  countConversation?: number,
  settings?: any,
  parentConversations: any,
  activeConversion?: IConversation,
  conversations: IConversation[],
  message: IConversationMessage,
  messages: IConversationMessage[],
  activeTab: number,
}

const initialState: ChatState = {
  isLoading: false,
  showComposer: false,
  showConversion: false,
  showNewConversion: false,
  settings: null,
  countConversation: 0,
  parentConversations: null,
  activeConversion: null,
  conversations: null,
  message: null,
  messages: [],
  activeTab: 1
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setLoading(state: ChatState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setChatActiveTab(state: ChatState, action: PayloadAction<number>) {
      state.activeTab = action.payload;
    },
    setInboxSetting(state: ChatState, action: PayloadAction<{ settings: any }>) {
      state.settings = action.payload.settings;
    },
    setCountConversation(state: ChatState, action: PayloadAction<number>) {
      state.countConversation = action.payload;
    },
    setShowComposerMessage(state: ChatState, action: PayloadAction<boolean>) {
      state.showComposer = action.payload;
    },
    setShowConversionMessage(state: ChatState, action: PayloadAction<boolean>) {
      state.showConversion = action.payload;
    },
    setShowNewConversionMessage(state: ChatState, action: PayloadAction<boolean>) {
      state.showNewConversion = action.payload;
    },
    setConversation(state: ChatState, action: PayloadAction<{ conversations: IConversation[]; }>) {
      const { conversations } = action.payload;
      state.conversations = conversations;
    },
    setCloseConversation(state: ChatState, action: PayloadAction<string>) {
      const { activeConversion, countConversation, conversations } = state;
      if (state.activeConversion.id === action.payload) {
        state.activeConversion = {
          ...activeConversion,
          isClosed: true
        };
        state.countConversation = countConversation - 1;
        state.conversations = conversations.map(conversation => {
          if (conversation.id === action.payload) {
            return {
              ...conversation,
              isClosed: true
            }
          }
          return conversation;
        })
      } else {
        state.activeConversion = activeConversion;
      }
    },
    setOpenConversation(state: ChatState, action: PayloadAction<string>) {
      const { activeConversion, countConversation, conversations } = state;
      if (state.activeConversion.id === action.payload) {
        state.activeConversion = {
          ...activeConversion,
          isClosed: false
        };
        state.countConversation = countConversation + 1;
        state.conversations = conversations.map(conversation => {
          if (conversation.id === action.payload) {
            return {
              ...conversation,
              isClosed: false
            }
          }
          return conversation;
        })
      } else {
        state.activeConversion = activeConversion;
      }
    },
    setActiveConversion(state: ChatState, action: PayloadAction<{ conversation: IConversation; }>) {
      const { conversation } = action.payload;
      state.activeConversion = conversation;
    },
    setConversionMessages(state: ChatState, action: PayloadAction<{ messages: IConversationMessage[], concat?: boolean }>) {
      if (action.payload.concat) {
        if (action.payload.messages) {
          state.messages = [
            ...action.payload.messages,
            ...state.messages
          ];
        }
      } else {
        state.messages = action.payload.messages;
      }
    },
    setParentConversation(state: ChatState, action: PayloadAction<{ conversations: any }>) {
      const { conversations } = action.payload;
      state.parentConversations = conversations;
    },
    pushMessageOnThread(state: ChatState, action: PayloadAction<{ conversations: any }>) {
      const { conversations } = action.payload;
      const data = [conversations, ...state.conversations];
      state.conversations = data;
    },
    markThreadAsRead(state: ChatState, action: PayloadAction<{ conversation: IConversation }>) {
      const { conversation } = action.payload;
      const index = findIndex(state.conversations, { id: conversation.id });
      const parent = conversation?.parent ? conversation.parent : state?.conversations[index]?.parent;
      state.conversations[index] = {
        ...conversation,
        parent: parent,
        unreadMessagesCount: 0
      };
    },
    addMessage(state: ChatState, action: PayloadAction<{ message: IConversationMessage, messageId?: string }>) {
      const { message, messageId } = action.payload;
      const { messages, activeConversion, showConversion, showNewConversion, countConversation } = state;
      const index = findIndex(state.conversations, { id: activeConversion?.id });
      const conversation = cloneDeep(state.conversations[index]);
      if (showConversion || showNewConversion) {
        const lastMessage = message?.lastMessage ? message?.lastMessage : message;
        if (messageId) {
          const messageIndex = findIndex(messages, { id: messageId });
          if (messageIndex >= 0) {
            messages[messageIndex] = lastMessage;
          }
        } else {
          messages.push(message);
        }
        if (message?.lastMessage) {
          state.conversations.push({
            ...message,
            messagesCount: 1,
            parent: activeConversion?.parent
          });
          state.activeConversion = {
            ...state.activeConversion,
            lastMessage
          }
          state.countConversation = countConversation + 1;
        }
      } else {
        if (messageId) {
          const messageIndex = findIndex(messages, { id: messageId });
          if (messageIndex >= 0) {
            messages[messageIndex] = message;
          }
        } else {
          messages.push(message);
        }
        if (conversation) {
          conversation.lastMessage = message;
          state.conversations[index] = {
            ...conversation,
            messagesCount: (message?.status && conversation.messagesCount + 1) || conversation.messagesCount
          };
        }
      }
    },
    replyMessage(state: ChatState, action: PayloadAction<{ message: IConversationMessage, showComposer: boolean }>) {
      state.message = action.payload.message;
      state.showComposer = action.payload.showComposer;
    }
  }
});

export const reducer = slice.reducer;

// const twilioMessageService = TwilioMessageService.getInstance<TwilioMessageService>();
const conversationMessageService = ConversationMessageService.getInstance<ConversationMessageService>();
const threadService = ThreadService.getInstance<ThreadService>();
const parentProviderService = ParentProviderService.getInstance<ParentProviderService>();
const providerService = ProviderService.getInstance<ProviderService>();

export const getInboxSetting = (settings: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInboxSetting({ settings }))
};

export const setChatActiveTab = (activeTab: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChatActiveTab(activeTab))
};

export const pushMessageOnThread = (conversation: any): AppThunk => async (dispatch) => {
  if (conversation.isReplay) return;
  const conversationInfo = { ...conversation.thread, messagesCount: conversation.messagesCount || 1, parent: conversation.parent, lastMessage: { ...conversation.thread.lastMessage, type: conversation.type  }, }
  dispatch(slice.actions.pushMessageOnThread({ conversations: conversationInfo }))
};

export const getParentConversation = (threadParentType: number): AppThunk => async (dispatch) => {
  return parentProviderService.getParentMembers(threadParentType).then((response) => {
    const sortedConversations = orderBy(response.data, [conversation => conversation?.firstName?.toLowerCase()], ['asc']);
    const conversations = {};
    sortedConversations.map((conversation) => {
      const alphabetical = conversation.firstName.substring(0, 1);
      if (!conversations[alphabetical.toUpperCase()]) {
        conversations[alphabetical.toUpperCase()] = [conversation];
      } else {
        conversations[alphabetical.toUpperCase()].push(conversation);
      }
      return conversation;
    });
    dispatch(slice.actions.setParentConversation({ conversations }))
    return response;
  });
};

export const getConversation = (threadType: number, hasMobileDevice?: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoading(true));
  await dispatch(slice.actions.setConversionMessages({ messages: [] }));
  await dispatch(slice.actions.setActiveConversion({ conversation: null }));
  return threadService.getThreads(threadType).then(async (response) => {
    const sortedConversations = reverse(sortBy(response.data.items, (item) => moment(item?.lastMessage?.creationTimeUTC || item?.lastMessage.creationTime).unix()));
    dispatch(slice.actions.setConversation({ conversations: sortedConversations }));

    if (sortedConversations.length > 0 && !hasMobileDevice) {
      const conversation = sortedConversations[0];
      await dispatch(setActiveConversion(conversation));
    }
    if (threadType === 1) {
      dispatch(slice.actions.setCountConversation(response?.data?.totalCount))
    }
    dispatch(slice.actions.setLoading(false));
    return response;
  }).catch(() => {
    dispatch(slice.actions.setLoading(false));
  });
};

export const filterConversion = (type: string, order): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const conversations = state.chat.conversations.map(conversation => ({ ...conversation, lastName: conversation?.parent?.lastName?.toLowerCase() }))
  const sortedConversations = orderBy(conversations, [type], [order]);
  await dispatch(slice.actions.setConversation({ conversations: sortedConversations }));
};

export const setActiveConversion = (conversation: IConversation) => async (dispatch, getState) => {
  const state = getState();
  if (state.chat.activeConversion?.id === conversation.id) {
    return false;
  }
  await dispatch(slice.actions.setActiveConversion({ conversation }));
  if (!conversation?.lastMessage?.readAt) {
    dispatch(markThreadAsRead(conversation));
  }
};

export const clearActiveConversion = (conversation: IConversation) => (dispatch) => {
  dispatch(slice.actions.setActiveConversion({ conversation }));
  dispatch(slice.actions.replyMessage({ message: null, showComposer: false }));
};

export const showComposerMessage = (isOpen: boolean) => (dispatch) => {
  dispatch(slice.actions.setShowComposerMessage(isOpen));
};

export const showConversionMessage = (isOpen: boolean) => (dispatch) => {
  dispatch(slice.actions.setShowConversionMessage(isOpen));
};

export const showNewConversionMessage = (isOpen: boolean) => (dispatch) => {
  dispatch(slice.actions.setShowNewConversionMessage(isOpen));
};

export const getConversionMessages = (conversation: IConversationMessage, request?: IPaginationRequest) => (dispatch) => {
  let concat = false;
  if (request?.SkipCount > 0) {
    concat = true;
  }
  if (!concat) {
    dispatch(slice.actions.setConversionMessages({ messages: [] }))
  }
  return conversationMessageService.byThreadId(conversation?.threadId, request).then((response) => {
    const messages = response.data.items;
    dispatch(slice.actions.setConversionMessages({
      messages,
      concat
    }));
    return response;
  });
};

export const clearConversionMessages = () => (dispatch) => {
  dispatch(slice.actions.setConversionMessages({ messages: [] }))
};

export const addReplyConversionMessage = (message: IConversationMessage, showComposer?: boolean) => (dispatch) => {
  dispatch(slice.actions.replyMessage({ message, showComposer }));
};

export const addConversionMessage = (message: IConversationMessage, parentNumber?: string) => (dispatch, getState) => {
  const state = getState();
  if (parentNumber) {
    const hasCountryCode = parentNumber.indexOf('+') === 0;
    parentNumber = parentNumber?.replace(/\D/g, "");
    parentNumber = hasCountryCode ? `+${parentNumber}` : `+1${parentNumber}`
  }
  const pendingMessage = {
    id: uniqueId(),
    ...message,
    author: ConversationAuthorEnum.PROVIDER,
    status: ConversationStatusEnum.PENDING,
  }
  dispatch(slice.actions.addMessage({ message: pendingMessage }));
  if (message.type === ConversationTypeEnum.EMAIL) {
    return providerService.sendEmail({
      emailsTo: [
        state.chat.activeConversion?.parent?.email
      ],
      body: message?.message,
      subject: message?.subject,
      threadId: message?.threadId,
      isReplay: message?.isReplay
    }).then((response) => {
      if (state.chat.showConversion || state.chat.showNewConversion) {
        const lastMessage = response.data?.thread ? response.data?.thread : response.data;
        dispatch(slice.actions.addMessage({ message: lastMessage, messageId: pendingMessage.id }));
      } else {
        dispatch(slice.actions.addMessage({ message: response.data, messageId: pendingMessage.id }));
      }
      return response;
    });
  } 
  // else if (message.type === ConversationTypeEnum.TWILIO_CONVERSATION) {
  //   return twilioMessageService.sendTwilioMessage(message.threadId, {
  //     parentNumber: message.parent,
  //     text: message.message,
  //   }).then((response) => {
  //     dispatch(slice.actions.addMessage({ message: response.data, messageId: pendingMessage.id }));
  //     return response;
  //   });
  // } 
  else {
    return conversationMessageService.create(message).then((response) => {
      if (state.chat.showConversion || state.chat.showNewConversion) {
        const lastMessage = response.data?.thread ? response.data?.thread : response.data;
        dispatch(slice.actions.addMessage({ message: lastMessage, messageId: pendingMessage.id }));
      } else {
        dispatch(slice.actions.addMessage({ message: response.data, messageId: pendingMessage.id }));
      }
      return response;
    });
  }
};

export const markThreadAsRead = (conversation: IConversation) => (dispatch) => {
  if (!conversation?.lastMessage) {
    return false;
  }
  return threadService.markAsRead(conversation?.lastMessage.threadId).then((response) => {
    dispatch(slice.actions.markThreadAsRead({ conversation: response.data }));
    return response;
  });
};

export const closeConversion = (conversationId: string, threadId: string) => (dispatch) => {
  return threadService.closeThread(threadId).then((response) => {
    dispatch(slice.actions.setCloseConversation(conversationId));
    return response;
  })
};

export const reopenConversion = (conversationId: string, threadId: string) => (dispatch) => {
  return threadService.reopenThread(threadId).then((response) => {
    dispatch(slice.actions.setOpenConversation(conversationId));
    return response;
  })
};
export default slice;
